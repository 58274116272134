import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { confirmPurchase, getPurchaseOf } from '@/api/index';
import SignBoard from '@/components/sign/SignBoard';
import style from './Contract.scss';
import TextBooks from './TextBooks';
import Modal from './Modal';
import ServiceProvider from './ServiceProvider';
import GoodPrivacyPolicy from './privacyPolicy/GoodPrivacyPolicy';
import { FCheck, Ic } from '@/components/basic/index';
import { DateFormat, DItemGroup, Section, SubSection } from '@/components/shared/index';
import { isEmpty } from '@/util/util';
import GoodFee from '@/components/contract/fee/GoodFee';

const { company, confirmation } = window.env;

const Contract = ({ purchaseId = null }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [purchase, setPurchase] = useState(null);
  const [family, setFamily] = useState(null);
  const [firstStudent, setFirstStudent] = useState(null);
  const [multiGradeReason, setMultiGradeReason] = useState({});
  const [confirm13Items, setConfirm13Items] = useState({});
  const [sign, setSign] = useState(null);
  const [customerAdmit, setCustomerAdmit] = useState(false);
  const [show13Items, setShow13Items] = useState(false);
  const [confirmations, setConfirmations] = useState([]);

  useEffect(() => {
    (async () => {
      await loadPurchase();
    })();
  }, [purchaseId]);

  const loadPurchase = async () => {
    const currentPurchase = await getPurchaseOf({ id: purchaseId });
    setFamily(currentPurchase.family);
    setSign(currentPurchase.signature);
    setFirstStudent(currentPurchase.family.students[0]);
    setPurchase(currentPurchase);
    const multiReason = (currentPurchase.multiGradeReason ?? '').split('\t').reduce((a, i) => {
      return { ...a, [i]: true };
    }, {});
    setMultiGradeReason(multiReason);
    setCustomerAdmit(currentPurchase.customerAdmit);
    setConfirm13Items(
      currentPurchase.confirm13Items
        ? [...Array(13).keys()].reduce((a, i) => {
            return { ...a, [i]: true };
          }, {})
        : {}
    );
    setShow13Items((currentPurchase.coachingText || '').includes('テキスト有'));
    if (currentPurchase.contractDate === null) {
      setConfirmations(confirmation[confirmation.length - 1].items);
    } else {
      const needle = currentPurchase.contractDate.replace('-', '');
      const c = _.last(
        confirmation.filter((c) => {
          return c.version < needle;
        })
      );
      setConfirmations(c ? c.items : []);
    }
  };

  const textOf = (purchaseTextbookItem) => {
    return purchaseTextbookItem.map((pti) => {
      const subjectNames = pti.purchaseTextbookItemSubjects
        .map((s) => {
          return s.subject;
        })
        .join(',');
      return {
        type: pti.categoryName,
        grade: pti.gradeName,
        subjects: `${pti.purchaseQuantity}教科`,
        text: `${pti.textName}(${subjectNames})`,
        price: pti.price,
      };
    });
  };

  const onMultiGradeReasonChange = (e) => {
    setMultiGradeReason({ ...multiGradeReason, [e.target.value]: e.target.checked });
  };
  const onConfirm13ItemsChange = (e) => {
    setConfirm13Items({ ...confirm13Items, [e.target.value]: e.target.checked });
  };
  const prepareDoSign = () => {
    if (!(purchase.coachingText || '').includes('テキスト有')) {
      return true;
    }
    return (
      Object.keys(confirm13Items).map((c) => {
        return confirm13Items[c];
      }).length === 13
    );
  };

  const save = async () => {
    if (!sign) {
      return;
    }
    const reason = Object.keys(multiGradeReason)
      .filter((k) => {
        return multiGradeReason[k];
      })
      .join('\t');
    await confirmPurchase({
      id: purchase.id,
      sign,
      multiGradeReason: reason,
      confirm13Items: true,
      acknowledgment: purchase.acknowledgment,
      allowElectronicDelivery: purchase.allowElectronicDelivery,
    });
    toast('保存しました。');
    await loadPurchase();
  };

  const showHelpModal = ({ title = '', body = '' }) => {
    setModalTitle(title);
    setModalContent(body);
    setShowModal(true);
  };

  return (
    <>
      {purchase && (
        <>
          <Modal
            show={showModal}
            title={modalTitle}
            body={modalContent}
            onHide={() => setShowModal(false)}
            footer={
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                閉じる
              </Button>
            }
          />
          <Section title="基本情報" first>
            <DItemGroup label={'コース'}>{purchase.coachingText}</DItemGroup>
            <DItemGroup label={'申込日'}>
              <DateFormat date={family.applyDate} />
            </DItemGroup>
            <DItemGroup label={'契約日'}>
              <DateFormat date={purchase.contractDate} />
            </DItemGroup>
            <DItemGroup label={'氏名'}>
              <div className={style.kana}>
                {family.familyNameKana} {family.firstNameKana}
              </div>
              <div className={style.name}>
                {family.familyName} {family.firstName}
              </div>
            </DItemGroup>
            <DItemGroup label={'生年\n月日'}>
              <DateFormat date={family.birthDate} />
            </DItemGroup>
            <DItemGroup label={'ご住所'}>
              {family.prefecture}
              {family.address1}
              {family.address2}
            </DItemGroup>
            <DItemGroup label={'ご連絡先'}>
              {!isEmpty(family.tel) ? (
                <div>
                  <span style={{ display: 'inline-block', width: '25px', textAlign: 'center' }}>
                    <Ic iName="faHouse" />
                  </span>
                  <span>{family.tel}</span>
                </div>
              ) : (
                <></>
              )}
              {!isEmpty(family.mobileTel) ? (
                <div>
                  <span style={{ display: 'inline-block', width: '25px', textAlign: 'center' }}>
                    <Ic iName="faMobileScreenButton" />
                  </span>
                  <span>{family.mobileTel}</span>
                </div>
              ) : (
                <></>
              )}
            </DItemGroup>
            {/*<DItemGroup label={'ご家族\n構成'}>*/}
            {/*  <span>{family.married}</span> 子({family.kids}人)：他({family.otherFamily}人)*/}
            {/*</DItemGroup>*/}
            {/*<DItemGroup label="世帯収入">{family.houseIncome}</DItemGroup>*/}
            {family.students.map((student, index) => {
              return (
                <React.Fragment key={index}>
                  <SubSection title={'生徒' + (index + 1)}>
                    <DItemGroup label={'氏名'}>
                      <div className={style.kana}>
                        {student.familyNameKana} {student.firstNameKana}
                      </div>
                      <div className={style.name}>
                        {student.familyName} {student.firstName}
                      </div>
                    </DItemGroup>
                    <DItemGroup label={'生年\n月日'}>
                      <DateFormat date={student.birthDate} />
                    </DItemGroup>
                    <DItemGroup label={'学校名'}>
                      {student.schoolCategory}
                      {student.schoolName}
                      {student.schoolKind}
                    </DItemGroup>
                    <DItemGroup label={'学年'}>{student.grade}</DItemGroup>
                  </SubSection>
                </React.Fragment>
              );
            })}
          </Section>
          <Section title="ご契約内容">
            <div className={style.memo}>
              <span
                className={style.link}
                onClick={() =>
                  showHelpModal({
                    title: '入会金について',
                    body: '生徒の初期カルテ作成・家庭教師初期登録費用として。教師追加 1 名につき 22,000 円、生徒追加 1 名につき 11,000 円必要。 退会後の再入会には、入会金が必要。有効期限:高校卒業まで。',
                  })
                }
              >
                入会金について
              </span>
              <span
                className={style.link}
                onClick={() =>
                  showHelpModal({
                    title: '保証金について',
                    body: '入会中に生じる債務担保として(利息なし)。退会時は18,000円から 未払い債務を差引いた金額をご返金します。(ご返金完了時退会)',
                  })
                }
              >
                保証金について
              </span>
            </div>
            <DItemGroup label={'入会金/\n保証金'}>
              {purchase.admission}円/{purchase.deposit}円
            </DItemGroup>
            <DItemGroup label="振込期日">
              <DateFormat date={purchase.admissionIncomeDate} />
            </DItemGroup>
            <div className={style.memo}>
              <span
                className={style.link}
                onClick={() =>
                  showHelpModal({
                    title: 'コース名・指導料について',
                    body: '指導料/交通費は、実際回数分を後払いで直接家庭教師にお支払い。',
                  })
                }
              >
                コース名・指導料について
              </span>
            </div>
            <DItemGroup label={'コース名\n指導料'}>
              <div>
                週{firstStudent.teachAmount}回 {firstStudent.teachTime}分指導 {family.charge}円/h
              </div>
              <div className={style.memo}>※指導回数、時間はお客様で自由に変更できます</div>
            </DItemGroup>
            <DItemGroup label="交通費">実費交通費 × 月間指導回数(車 , バイク 15 円 /1km)</DItemGroup>
            <DItemGroup label={'役務提供\n期間'}>
              <div className={style.memo}>(役務期間の更新は応相談)</div>
            </DItemGroup>
            <DItemGroup label="生徒1">
              <DateFormat date={firstStudent.start1} />〜<DateFormat date={firstStudent.end1} />
              まで
            </DItemGroup>
            {family.students[1] ? (
              <DItemGroup label="生徒2">
                <DateFormat date={family.students[1].start1} />〜<DateFormat date={family.students[1].end1} />
                まで
              </DItemGroup>
            ) : (
              <></>
            )}
            <DItemGroup label={''}>
              <div className={style.memo}>役務提供内容(種類及び提供の方法)家庭教師の紹介・サポート</div>
            </DItemGroup>
            {('訪問指導テキスト無' === purchase.coachingText ||
              'オンライン指導テキスト無' === purchase.coachingText) && (
              <>
                <DItemGroup label={'基本料金 / 月額'}>
                  <div>{purchase.supportPayment}円</div>
                </DItemGroup>
                <div className={style.memo}>
                  <span
                    className={style.link}
                    onClick={() =>
                      showHelpModal({
                        title: '基本料金 / 月額について',
                        body: '関連商品使用時と異なり、ご家庭ごとの学習スタイルに対応するための費用です。初月分のご入金確認後、家庭教師をご紹介いたします。',
                      })
                    }
                  >
                    基本料金 / 月額について
                  </span>
                </div>
                {'月々のお支払い' === purchase.supportMethods && (
                  <>
                    <DItemGroup label={'引落開始日'}>
                      <DateFormat date={purchase.supportDivisionStart} />({purchase.supportDivisionTimes}月分)
                    </DItemGroup>
                  </>
                )}
                {'一括振込' === purchase.supportMethods && (
                  <>
                    <DItemGroup label="振込一括">
                      <div>
                        割引金額：{purchase.supportDiscount}円・振込金額：{purchase.supportTransfer}円
                      </div>
                    </DItemGroup>
                    <DItemGroup label={'初月\n支払方法'}>
                      <DateFormat format={'YYYY年MM月'} date={purchase.supportFirstMonth} />
                    </DItemGroup>
                  </>
                )}
              </>
            )}
          </Section>
          {('訪問指導テキスト有' === purchase.coachingText || 'オンライン指導テキスト有' === purchase.coachingText) && (
            <>
              <Section title="関連商品名">
                <TextBooks textItems={textOf(purchase.purchaseTextbookItem)} purchase={purchase}></TextBooks>
                <DItemGroup label={'支払期間'}>
                  {'現金一括' !== purchase.contractKind && (
                    <>
                      <DateFormat format={'YYYY年MM月'} date={purchase.paymentStartDate} />〜
                      <DateFormat format={'YYYY年MM月'} date={purchase.paymentEndDate} />
                    </>
                  )}
                </DItemGroup>
                <DItemGroup label={'支払回数'}>
                  {purchase.contractKind === '現金一括' ? 1 : purchase.paymentCount}回
                </DItemGroup>
              </Section>
              <Section title="購入テキスト支払方法/金額">
                <div className={style.memo}>ボーナス払...夏期6・7・8月、冬期 12・1月から選択</div>
                <DItemGroup label={'支払方法'}>{purchase.contractKind}</DItemGroup>
                {'現金一括' === purchase.contractKind && (
                  <>
                    <DItemGroup label={'期日'}>
                      <DateFormat date={purchase.finishDate} />
                    </DItemGroup>
                  </>
                )}
                {'現金一括' !== purchase.contractKind && (
                  <>
                    <DItemGroup label={'初月金額'}>{purchase.firstIncome}円</DItemGroup>
                    <DItemGroup label={'2ヶ月目\n以降'}>{purchase.monthlyIncome}円</DItemGroup>
                    <DItemGroup label={'ボーナス月'}>{purchase.bonusAmount}円</DItemGroup>
                    <DItemGroup label={'ボーナス\n回数'}>
                      {purchase.bonusMonth1 && <>{purchase.bonusMonth1}月</>}
                      {purchase.bonusMonth2 && <>・{purchase.bonusMonth2}月</>}({purchase.bonusTimes}回)
                    </DItemGroup>
                  </>
                )}
              </Section>
            </>
          )}
          <Section title="事業者について">
            <div className={style.description}>
              <div>引落日...毎月27日(土日祝の場合は翌営業日)</div>
              <div>付帯する役務...有(家庭教師)</div>
              <div>関連商品の引渡時期等...申込後15日以内(入会金・保証金の入金必須)</div>
            </div>
            <DItemGroup label={'役務提供\n事業者'}>
              <ServiceProvider serviceProvider={purchase.serviceProvider} />
            </DItemGroup>
            <DItemGroup label={'関連商品\n販売事業者'}>
              <ServiceProvider serviceProvider={purchase.itemDistributor} />
            </DItemGroup>
          </Section>
          <Section title="家庭教師 指導料一覧">
            <GoodFee />
          </Section>
          <Section title={`当社(${company.name})の\n個人情報の取り扱いについて`}>
            <>
              <GoodPrivacyPolicy />
            </>
          </Section>
          <Section title={'ご承諾事項'}>
            <div className={style.confirmation}>
              <div className={style.multiAmountText}>
                <div style={{ marginBottom: '0.5rem' }}>
                  学習テキストを2学年分以上ご購入の場合、理由にチェックしてください
                </div>
                <ul>
                  {[
                    { id: 0, cause: '同じ指導方法とテキストで学習する事で、継続的な教育効果を持たせるため' },
                    { id: 1, cause: '進級・進学に向け先取り学習や戻り学習をするため' },
                    { id: 2, cause: '進級・進学に向け兄弟で使用するため' },
                    { id: 3, cause: '学校の授業の進行に合わせて使用するため' },
                  ].map((c) => {
                    return (
                      <li key={c.id}>
                        <FCheck
                          disabled={customerAdmit}
                          label={c.cause}
                          value={c.id}
                          checked={multiGradeReason[c.id] ?? false}
                          change={onMultiGradeReasonChange}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              {
                <>
                  <div className={style.textDelivery}>
                    <div style={{ marginBottom: '1rem' }}> 自社割賦払いのお客様テキスト後日配送に関する承諾</div>
                    <div style={{ marginBottom: '1rem' }}>
                      今回ご契約いただきましたテキストの後日配送につきまして、当社はこの契約書を受理後一週間以内及び入会金の入金日より15日以内、いずれの条件も満たした場合、下記の通りに配送いたします。
                    </div>
                    <ul>
                      <li>
                        現学年分は、申込後15日以内(入会金・保証金の入金が確認できている条件を満たしている場合)に商品をお引渡しいたします
                      </li>
                      <li>次学年分は今年度末日までに、次々学年分は来年度末日までにお引渡しいたします</li>
                    </ul>
                    <div className={style.memo}>※テキストの中身、外観及びブランドが変更になることがあります。</div>
                    <div className={style.confirmButton}>
                      <FCheck
                        className="purchaseConfirm"
                        disabled={customerAdmit}
                        label={'上記の内容を了承しました'}
                        checked={purchase.acknowledgment}
                        change={(e) => {
                          setPurchase({ ...purchase, acknowledgment: e.target.checked });
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              {show13Items && (
                <>
                  <div className={style.agreement}>
                    <div className={style.subtitle} style={{ marginBottom: '0.5rem' }}>
                      学習ゼミお申込に関する確認
                    </div>
                    <div className={style.information}>
                      学習ゼミは、お子さんの予習・復習の習慣づけやテスト前のポイントに絞った勉強が、効率的にできるように考えて編集された「オリジナル」のテキストです。お子さんの成績アップのためにも有効活用していきます。
                    </div>
                    <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                      お手続きを円滑に進めるために・・・確認の流れ
                    </div>
                    {[
                      'ご契約されたお客様には、本部よりお申込内容について確認のお電話が入ります。',
                      '確認時には、「学習ゼミ申し込み確認シート」と「学習ゼミ申込書の控え」の2枚が必要です。',
                      '下記項目内容にご不明点がないか確認させていただきます。事前に下記項目内容に目を通していただけるとスムーズに確認することができますので、ご協力をお願いいたします。',
                      '最後に本書面内容と申込書を一緒に見ながら、学習ゼミの金額等の確認をさせていただき、問題がなければお申込みのお手続きが完了となります。',
                    ].map((r, i) => {
                      return (
                        <div key={i} style={{ paddingLeft: '0.5rem', marginBottom: '0.25rem' }}>
                          <div
                            style={{
                              width: '6%',
                              textAlign: 'right',
                              paddingRight: '0.5rem',
                              float: 'left',
                              fontSize: '1.1em',
                            }}
                          >
                            {i + 1}.
                          </div>
                          <div style={{ display: 'inline', float: 'left', width: '94%', fontSize: '1.2em' }}>{r}</div>
                          <div style={{ clear: 'both' }} />
                        </div>
                      );
                    })}
                    <div style={{ marginTop: '0.5rem' }}>
                      <span className={style.warning}>重要</span>
                      <span className={style.warnText}>
                        お申込みの手続きを完了するには、下記項目全てに問題がないことが条件となります。
                      </span>
                      <div style={{ marginTop: '0.25rem', marginBottom: '0.25rem' }}>
                        ※下記13項目についてご不明な点がございましたら、事前に担当者もしくは本部までお申し出ください。
                      </div>
                    </div>
                    <div>
                      <div style={{ marginBottom: '0.25rem' }}>13項目のご確認内容</div>
                      {confirmations.map((c, i) => {
                        return (
                          <div key={i} style={{ paddingLeft: '0.5rem', marginBottom: '0.25rem' }}>
                            <div style={{ width: '6%', textAlign: 'right', paddingRight: '0.5rem', float: 'left' }}>
                              {i + 1}.
                            </div>
                            <div style={{ display: 'inline', float: 'left', width: '94%' }}>
                              <FCheck
                                style={{ fontSize: '1.2em' }}
                                disabled={customerAdmit}
                                label={c}
                                value={i}
                                checked={confirm13Items[i] ?? false}
                                change={onConfirm13ItemsChange}
                              />
                            </div>
                            <div style={{ clear: 'both' }} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={style.confirmButton}>
              <FCheck
                className="purchaseConfirm"
                disabled={customerAdmit}
                label={
                  '私は、契約書面の書面交付に代えて、契約書面を電子交付で受け取ることの説明を受けて、電子交付を受けることに承諾しました。'
                }
                checked={purchase.allowElectronicDelivery}
                change={(e) => {
                  setPurchase({ ...purchase, allowElectronicDelivery: e.target.checked });
                }}
              />
            </div>
            <SignBoard
              readonly={customerAdmit}
              prepareDoSign={prepareDoSign()}
              initialImage={sign}
              onChange={(sign) => {
                setSign(sign);
              }}
              onSave={() => save()}
            />
          </Section>
          <Section title="連絡先">
            <div className={style.reach}>
              <div>
                本契約内容を書面にて発送いたします。ご不明な点がありましたら、下記ご相談窓口までお願いいたします。(電子交付の場合は発送いたしません)
              </div>
              <div>ご相談窓口</div>
              <div>電話番号{company.information.tel}</div>
              <div>{company.information.businessTime}</div>
              {company.information.mail && (
                <>
                  <div>
                    <a href={`mailto:${company.information.mail}`}>メール</a>でのお問合せ
                  </div>
                </>
              )}
              {
                <>
                  <div>
                    ご入会後の流れは<a href={`${company.customerGuideUrl}`}>こちら</a>をご確認ください
                  </div>
                </>
              }
            </div>
          </Section>
        </>
      )}
    </>
  );
};
export default Contract;
