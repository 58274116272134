import React, { useEffect, useState } from 'react';
import { getReports } from '@/api/index';
import style from '@/pages/report/Report.scss';
import { DateFormat, Section } from '@/components/shared/index';
import { Container } from 'react-bootstrap';

const ReportList = ({ studentId, toReportDetail }) => {
  const [reports, setReports] = useState([]);
  useEffect(async () => {
    const reports = await getReports(studentId);
    setReports(reports);
  }, []);
  const status = (report) => {
    if (report.settlement && report.settlement.isPaymented) {
      return { label: '清算済', class: style.paymented };
    }
    if (report.isApproval) {
      return { label: '承認済', class: style.approved };
    }
    return { label: '承認待ち', class: style.turnIn };
  };
  return (
    <Container fluid className={style.listWrapper}>
      <Section title="指導報告書一覧">
        {reports.map((report, i) => {
          return (
            <div className={status(report).class} key={i} onClick={() => toReportDetail(report.id)}>
              <div className={style.status}>{status(report).label}</div>
              <div className={style.date}>
                <div>
                  <span>指導日</span>
                  <DateFormat date={report.date} />
                </div>
                <div>
                  <span>先生</span>
                  {report.teacher.familyName}
                  {report.teacher.firstName}
                </div>
              </div>
              <div style={{ clear: 'both' }} />
            </div>
          );
        })}
      </Section>
    </Container>
  );
};

export default ReportList;
